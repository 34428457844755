import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilAddressBook,
  cilCalculator,
  cilFeaturedPlaylist,
  cilLibrary,
  cilMoney,
  cilSettings,
  cilSpeedometer,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react-pro'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: 'info-gradient',
      text: 'NEW',
    },
  },
  {
    component: CNavTitle,
    name: 'Customer',
  },
  {
    component: CNavItem,
    name: 'Customers',
    to: '/customers',
    icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Receivables',
    to: '/receivables',
    icon: <CIcon icon={cilFeaturedPlaylist} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Aging Summary',
  //   to: '/aging-summary',
  //   icon: <CIcon icon={cilFeaturedPlaylist} customClassName="nav-icon" />,
  // },
  {
    component: CNavTitle,
    name: 'Billing',
  },
  {
    component: CNavItem,
    name: 'Billing',
    to: '/billings',
    icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Payments',
    to: '/payments',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Adjustments',
    to: '/adjustments',
    icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Issues',
  },
  {
    component: CNavItem,
    name: 'Issues',
    to: '/service-issues',
    icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Users',
        to: '/icons/coreui-icons',
        badge: {
          color: 'success-gradient',
          text: 'ONLINE',
        },
      },
      {
        component: CNavItem,
        name: 'Roles',
        to: '/icons/flags',
      }
    ],
  },
]

export default _nav
