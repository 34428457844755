import React, { createContext } from 'react'
import { CCol, CContainer, CRow } from '@coreui/react-pro'

export const ViewportContext = createContext({})

export const ViewportContextProvider = ({ children }) => {

  const disableCheckResolution = window.localStorage?.getItem('disableCheckResolution') === 'Y'
  const screenResLimit = 1230
  const [isSmallScreen, setIsSmallScreen] = React.useState(window.innerWidth < screenResLimit)
  const [width, setWidth] = React.useState(window.innerWidth)
  const [height, setHeight] = React.useState(window.innerHeight)

  const handleWindowResize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
    setIsSmallScreen(window.innerWidth < screenResLimit)
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  const ScreenMessage = () => {
    return <>    
      <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
        <div className="body flex-grow-1 px-3">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol className='mt-3'>
                <p style={{color:'white', textAlign: 'center'}}>Our apologies. We do not support smaller screens as of this time.</p>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </div>
    </>
  }

  return (
    isSmallScreen && !disableCheckResolution ? (
      <ScreenMessage />
    ) : (
      <ViewportContext.Provider value={{ width, height }}>
        {children}
      </ViewportContext.Provider>
    )
  )
}

export const useViewport = () => {
  
  const { width, height } = React.useContext(ViewportContext)
  return { width, height }
}
