import React from 'react'
import { CFooter } from '@coreui/react-pro'

const AppFooter = () => {
  return (
    <CFooter>
      <div>        
      </div>
      <div className="ms-auto" style={{color: "white"}}>        
        nexus.one
        <span className="ms-1">&copy; 2024 </span>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
