import React from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilPaint,
  cilWarning,
} from '@coreui/icons'

const AppHeaderDropdownNotif = () => {

  const notifications = [
    'Change Meters: Set the status of the old meter to Defective Meter, then create a new customer with the new meter',
    'Deposit: Apply Deposits to Current bills to deduct receivables'
  ]

  const itemsCount = notifications.length + 2
  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle caret={false}>
        <CIcon icon={cilBell} size="lg" className="my-1 mx-2" />
        <CBadge
          shape="rounded-pill"
          color="danger-gradient"
          className="position-absolute top-0 end-0"
        >
          {itemsCount}
        </CBadge>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0">
        <CDropdownHeader className="bg-light dark:bg-white dark:bg-opacity-10">
          <strong>You have {itemsCount} notifications</strong>
        </CDropdownHeader>
        <CDropdownItem>
          <CIcon icon={cilPaint} className="me-2 text-success" /> Implementation Phase: In Progress.
        </CDropdownItem>
        <CDropdownItem>
          <CIcon icon={cilWarning} className="me-2 text-danger" /> Customers: Should have a meter serial no. to be able to identify them properly.
        </CDropdownItem>
        {
          notifications.map(note=>{
            return <>
              <CDropdownItem>{note}</CDropdownItem>
            </>
          })
        }
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdownNotif
