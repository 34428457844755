import React from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CProgress,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilApplicationsSettings, cilList } from '@coreui/icons'

const AppHeaderDropdownTasks = () => {
  const itemsCount = 5
  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle caret={false}>
        <CIcon icon={cilList} size="lg" className="my-1 mx-2" />
        <CBadge
          shape="rounded-pill"
          color={
            process.env.REACT_APP_ENV == 'dev' ? 'warning' :
              process.env.REACT_APP_ENV == 'uat' ? 'warning' : 'success'} 
          className="position-absolute top-0 end-0"
        >
          {process.env.REACT_APP_ENV == 'prod' ? 'live' : process.env.REACT_APP_ENV}
        </CBadge>
      </CDropdownToggle>
      <CDropdownMenu placement="bottom-end" className="pt-0">
        <CDropdownHeader className="bg-light dark:bg-white dark:bg-opacity-10">
          <strong>Environment</strong>
        </CDropdownHeader>
        <CDropdownItem className="d-block">
          <CIcon icon={cilApplicationsSettings} className="me-2 text-success" /> {process.env.REACT_APP_ENV}
        </CDropdownItem>
        <CDropdownItem className="d-block">
          <div className="small mb-1">
            Nexus.one Implementation Phase
          </div>
          <CProgress thin color="danger-gradient" value={25} />
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdownTasks
