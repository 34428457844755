import React, { useEffect } from 'react'
import { useLocation } from "@reach/router"
import { useAuth } from './../AuthContextProvider'
import useNavigateNext from './../../common/useNavigateNext'

const Auth = () => {

  const [_, {sso, getCookie, setAuth }]= useAuth()
  const location = useLocation()
  const [searchParams, navigateNext] = useNavigateNext(location)
  const abort = new AbortController()
  console.log('AUTH')
  useEffect(()=>{
    const tokenParam = searchParams.get('token')
    const refreshParam = searchParams.get('refresh')
    if(tokenParam){
      console.log('AUTH SSO',tokenParam,refreshParam)
      sso(tokenParam,refreshParam)
      .then((data)=>{
        console.log('AUTH sso successful', data)
        setAuth({
          email: data.email,
          role: data.role,
          profit_center: data.profit_center
        })
        navigateNext('/dashboard')
      })
      .catch(err=>{
        location.googleSignIn = true
        navigateNext('/login')
      })
    } else {
      const cookies = getCookie('tkn-ath')
      console.log('Auth getCookie', cookies)
      const token = getCookie('tkn-ath')
      const refresh = getCookie('tkn-ath-rfrsh')
      if(token){
        console.log('AUTH navigate sso')
        //console.log('Auth sso from cookie')
        sso(token,refresh)
        .then((data)=>{
          console.log('AUTH sso', data)
          setAuth({
            email: data.email,
            role: data.role,
            profit_center: data.profit_center
          })
          location.pathname != '/auth' ? navigateNext(1) : navigateNext('/dashboard')
        })
        .catch(err=>{
          navigateNext('/login')
        })
      } else {
        console.log('AUTH navigate login')
        navigateNext('/login',{
          next: location.pathname
        })
      }
    }
    return ()=>{
      abort.abort()
    }     
  }, [])

  return null
}

export default Auth