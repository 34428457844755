import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { navigate } from "@reach/router"
import { CCard, CCardBody, CCol, CRow } from '@coreui/react-pro'

const ErrorBoundaryFallbackComponent = props => (    
  <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardBody>
            <div className="flex-grow-1 px-3">
              <form>
                <p className="welcome text-danger" style={{marginTop:"20px"}}>Oh, snap! Something went wrong.</p>
                <p className="subtext" style={{marginTop:"20px"}}>{ props?.error?.message }</p>
                <div className="form-group">
                  <a className="underline text-sm text-gray-600 hover:text-gray-900 signin" href="" onClick={()=>navigate(-1)}>
                    Go Back
                  </a>
                </div>
                <div className="form-group">
                  <a className="underline text-sm text-gray-600 hover:text-gray-900 signin" href="/login">
                    Go to Login
                  </a>
                </div>
              </form>
            </div>
          </CCardBody>
        </CCard>     
      </CCol>
  </CRow>
)

export default Component => props => {  
  return (
    <ErrorBoundary 
      FallbackComponent={ErrorBoundaryFallbackComponent}
    >
      <Component {...props} />  
    </ErrorBoundary>
  )
}
