import React from 'react'
//import logo from './../assets/images/login/logo.svg'

export const fallback = (
  <div className="container d-flex align-items-center justify-content-center">
    <div className="row">
      <div className="col-mid=12 mt-3 d-flex flex-column align-items-center">        
        <div className="spinner-border text-secondary" role="status"></div>
      </div>
    </div>
  </div>
)
