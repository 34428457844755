import React from 'react'
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react-pro'
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
  cilBlur,
  cilBlurCircular,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import avatar9 from './../../assets/images/avatars/9.jpg'
import { useAuth } from 'src/auth/AuthContextProvider'

const AppHeaderDropdown = () => {

  const [
    { user }, { PROFIT_CENTERS }
  ] = useAuth()

  console.log('AppHeaderDropdown', user)
  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={avatar9} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0">
        <CDropdownHeader className="bg-light dark:bg-white dark:bg-opacity-10 fw-semibold py-2">
          Account
        </CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilUser} className="me-2" />
          {user.email}
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem href="#">
          <CIcon icon={cilBlur} className="me-2" />
          {PROFIT_CENTERS[user.profit_center || 'global']?.['name']}
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem href="#">
          <CIcon icon={cilBlurCircular} className="me-2" />
          {process.env.REACT_APP_CURRENT_SCOPE || 'nexus:billing'}
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem href="/logout">
          <CIcon icon={cilLockLocked} className="me-2" />
          Lock Account
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
